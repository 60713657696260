'use client'

import { Timer } from "components/navs/bottomnav"
import HomePacks from "components/packs/homepacks"
import MyImage from "lib/image"
import Link from "next/link"
import { useEffect, useState } from "react"
import useSWR from "swr"
import Marquee from "react-fast-marquee";

const inclusions = ["⭐ VIP Pass for Hire A Camp customers", "⭐ New Year Special DJ Party", "⭐ Live Music Band", "⭐ Cake Cutting and Fireworks", "⭐ Laser Show & Sky Lantern", "⭐ Neon Party Bands", "⭐ Rain Dance", "⭐ Pool Party"]

const InclusionsMarquee = ({ text }) => {
    return (
        <div className="relative bg-black text-white h-6 flex items-center justify-center font-medium">
            {/* Marquee */}
            <Marquee autoFill={true} speed={100}>
                {/* <span className="text-xs flex items-center space-x-1 mx-2">
                    <FaStar />
                    <span>{text}</span>
                </span> */}
                {inclusions.map((item, index) => <span className="text-xs flex items-center mx-1" key={index}>
                    <span>{item}</span>
                </span>)}
            </Marquee>
        </div>
    );
};

let params = {
    filter: [`party_start_date = '2024-12-31'`, `party_end_date = '2024-12-31'`, 'type=package'], "sort": [
        "_count:desc",
    ], "direct": true
}

export default function NewYearCards() {
    // const [isMonoton, setIsMonoton] = useState(true);
    const { data } = useSWR('/api/event?slug=new-year');

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setIsMonoton((prev) => !prev);
    //     }, 400);
    //     return () => clearInterval(interval); // Cleanup on component unmount
    // }, []);

    return (
        <div className='mt-6' style={{ backgroundColor: '#FFE86C' }}>
            <div className="relative mb-0.5">
                <MyImage src="assets/banners/homepage/new-year-header.jpg" layout="responsive" width="358" height="110" />
                <div className="absolute -top-2 w-full h-full">
                    <h3 className={`pb-3 text-center text-3xl font-black`}>
                        <span className="font-overcameoutline">New Year</span><span className="font-overcamebold"> Bash</span>
                    </h3>
                    <p className="font-medium text-center text-sm">Biggest Party of the Year</p>
                </div>
            </div>
            <InclusionsMarquee text="Inclusions" />
            <div className="pt-4">
                <HomePacks api={'/api/external/ai/search'} params={params} query={'type=packages'} apiType='' />
                {data?.discount_countdown ? <Timer countdown={data?.discount_countdown} /> : null}
            </div>
            <Link href="/event/new-year">
                <MyImage src="assets/banners/homepage/vipass-homepage.jpg" layout="responsive" width="358" height="110" />
            </Link>
        </div>
    )
}   