'use client'

import MyImage from "lib/image"
import { useEffect, useRef, useState } from "react"
import { VscPlayCircle } from "react-icons/vsc"
import LazyLoad from "react-lazyload"

const cards = [
    {
        name: 'Matheran',
        image: 'uploads/f226ffa566b649378fb96b3b644dbb00.jpeg',
        link: '/forest-camping-in-matheran-mumbai',
        video: 'matheran-pool.mp4'
    },
    {
        name: 'Alibaug',
        image: 'uploads/b6a8cb688575417b9f382ad81fdb291c.webp',
        link: '/alibag-premium-beach-camping',
        video: 'premium-beach-camping.mp4'
    },
    {
        name: 'Alibaug Beach Camping',
        image: 'uploads/35eaf97e308d43ec99d04b06e007900e.jpeg',
        link: '/alibaug-beach-Camping',
        video: 'Alibag+beach+camping+(1).mp4'
    },
    {
        name: 'Premium Beach Camping',
        image: 'uploads/85f26e4b8f2f481eb47e26dd7cc272ef.jpeg',
        link: '/alibag-beach-camping',
        video: 'premium-beach-camping-revas.mp4'
    },
    {
        name: 'Premium Beach Camping',
        image: 'uploads/5d54a7347db54b88bb91dcce49a7d6ea.jpeg',
        link: '/floating-huts-kamshet-lonavala',
        video: 'floating-huts-bbq.mp4'
    },
    {
        name: 'Premium Beach Camping',
        image: 'uploads/3a61af74cace49d3995770d4278a4375.jpeg',
        link: '/tiny-house-near-pawna-lake',
        video: 'magical-tiny-house.mp4'
    },
    {
        name: 'Karjat',
        image: 'uploads/787238b76cd74ffebe86468d86695b79.jpeg',
        link: '/riverside-camping-karjat-near-mumbai-pune',
        video: 'karjat.mp4'
    },
]

const Card = ({ card, active, setActiveIndex, index }) => {
    const [videoReady, setVideoReady] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            if (active) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [active]);

    const handlePlay = (e) => {
        e.stopPropagation();
        setActiveIndex(index);
    }

    return <div className="h-60 w-[36vw] rounded-lg overflow-hidden relative" onClick={()=>window.open('https://www.hireacamp.com'+card.link)}>
        <video
            ref={videoRef}
            className={`w-full h-full object-cover ${videoReady ? '' : 'hidden'
                }`}
            src={`${process.env.NEXT_PUBLIC_CDN}assets/videos/${card.video}`}
            autoPlay
            loop
            muted
            onLoadedData={() => setVideoReady(true)}
            playsInline
        ></video>
        {(!active || !videoReady) && <MyImage src={card.image} layout="fill" className="object-cover" />}
        {(!active || !videoReady) && <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center">
            <VscPlayCircle className="text-white text-4xl" onClick={handlePlay} />
        </div>}
    </div>
}

export default function VideoCards() {
    const containerRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleScroll = () => {
        if (containerRef.current) {
            const cards = Array.from(containerRef.current.querySelectorAll(".card"));
            const leftmostIndex = cards.findIndex((card) => {
                const rect = card.getBoundingClientRect();
                return rect.left >= 0;
            });
            if (leftmostIndex !== -1 && leftmostIndex !== activeIndex) {
                setActiveIndex(leftmostIndex);
            }
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener("scroll", handleScroll);
        return () => container.removeEventListener("scroll", handleScroll);
    }, [activeIndex]);


    return (
        <div className='pt-2'>
            <h3 className={`pb-4 text-center text-4xl font-black font-overcamebold bg-gradient-to-r from-gray-800 via-gray-500 to-gray-700 text-transparent bg-clip-text`}>
                Trending Videos
            </h3>
            <div className="flex overflow-x-scroll hide-scroll-bar px-4" ref={containerRef}>
                <LazyLoad >
                    <div className="flex flex-nowrap space-x-2">
                        {cards.map((card, index) => (
                            <div key={index} className={`card`}>
                                <Card card={card} active={index === activeIndex} setActiveIndex={setActiveIndex} index={index} />
                            </div>
                        ))}
                    </div>
                </LazyLoad>
            </div>
        </div>
    )
}   