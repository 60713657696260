import Link from 'next/link'
import Image from "lib/image";
import { FaStar } from 'react-icons/fa'
import useSWRInfinite from 'swr/infinite'
import MySlider from 'components/utilities/slider';
import InfiniteScrollHorizontal from 'react-swr-infinite-scroll';
import Heart from 'components/packs/components/heart';
import { fetcher, useInfiniteFetch } from 'lib/useInfiniteFetch';
import LazyLoad from 'react-lazyload';
import { SinglePack as SinglePack1 } from './packs';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useContext } from 'react';
import { TbCircleChevronRight } from 'react-icons/tb';
import { DesktopMagicalPacks } from 'components/cards/magical';
import { DesktopSpecial } from 'components/index/special';
import { Context } from 'components/context';

export function Placeholder() {
    let rows = [];
    for (var i = 0; i < 4; i++) {
        rows.push(<div key={i} className="w-[47vw] md:w-[18vw] animate-pulse bg-gray-200 relative h-56 md:h-64 rounded-xl shadow-lg overflow-hidden">
            <div className="absolute bottom-0 left-0 w-full py-3 px-2 bg-gray-600 backdrop-filter backdrop-blur-lg bg-opacity-40">
                <div className="h-4 bg-gray-200 rounded mb-2 w-3/4">
                </div>
                <div className="flex justify-between items-center mt-1">
                    <div className="w-16 h-2 bg-gray-200 rounded"></div>
                    <div className="rounded-full bg-gray-200 h-4 w-4"></div>
                </div>
            </div>
        </div>)
    }
    return rows
}

export function SinglePack({ pack, slider = true, apiType }) {

    return (
        <Link
            key={pack.uname}
            href={"/[packname]"}
            as={"/" + pack.uname}
            className={`w-[47vw] md:w-[18vw]`}>

            <div className='relative h-56 md:h-64 w-full rounded-xl overflow-hidden'>
                {pack?.img ? <Image
                    className="object-cover"
                    layout="fill"
                    placeholder="blur"
                    blurDataURL={pack?.img?.blurData}
                    src={'uploads/' + pack?.img?.src}
                /> : null}
                <div className="absolute top-1 right-1 z-10">
                    <Heart uname={pack.uname} />
                </div>
                <div className="absolute rounded-b-xl bottom-0 left-0 w-full py-3 px-2 bg-gray-600 backdrop-filter backdrop-blur-lg bg-opacity-40">
                    <h2 className="text-white text-sm font-medium truncate">{pack.fancyname}</h2>
                    <div className='font-extralight flex items-center justify-between w-full text-xs text-white mt-0.5'>
                        {/* <p className="truncate">{pack.city?.name || pack.city}</p>
                        <div className='flex items-center'>
                            <FaStar
                                className="mr-1" />
                            <p>
                                {pack.rating ? (apiType === 'direct' ? JSON.parse(pack.rating).avg_rating : pack.rating) : 'New'}
                            </p>
                        </div> */}
                        <p className='text-white text-[0.6rem] font-medium truncate'>{pack.hightlight_text}</p>
                    </div>
                    <div className='font-extralight flex items-center justify-between w-full text-xs text-white mt-0.5'>
                    {pack.price ? <p className="text-xs flex items-center text-white"><span className="line-through text-xs">₹{pack.price + 500}</span>&nbsp;<span className="font-bold">₹{pack.price}</span></p> : null}
                        <div className='flex items-center'>
                            <FaStar
                                className="mr-1" />
                            <p>
                                {pack.rating ? (apiType === 'direct' ? JSON.parse(pack.rating).avg_rating : pack.rating) : 'New'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </Link>
    );
}

// export const DesktopHomePacks = ({ api, query, params, limit = 10, apiType }) => {

//     const swr = useSWRInfinite((index, prev) => `${api}?${query}&params=${params ? JSON.stringify(params) : ''}&page=${index + 1}&limit=${limit}`, fetcher, { refreshInterval: 0, revalidateFirstPage: false, initialSize: 1 })

//     return !swr.data ? <div className="flex flex-nowrap space-x-4"><Placeholder /></div> : <MySlider settings={{ slidesToScroll: 2 }}>
//         {swr.data?.map((d) => d?.map((pack, k) => <SinglePack key={pack.uname} pack={pack} apiType={apiType} />))}
//     </MySlider>
// }

const icons = [{ icon: 'verified_3d', label: 'Verified Properties' },
{ icon: 'secured_3d', label: 'Secure Payments' }, { icon: 'review_3d', label: 'Authentic Reviews' }]

export const DesktopHomePacks = ({ params, limit = 10, apiType }) => {
    const context = useContext(Context)

    const {
        data,
        error,
        isLoadingMore,
        size,
        setSize,
        isReachingEnd,
    } = useInfiniteFetch(`/api/external/ai/search`, `lat=${context.state?.currentLocation?.lat}&lng=${context.state?.currentLocation?.lng}&params=${params ? JSON.stringify(params) : ''}`)

    return <div className="pt-2">
        <InfiniteScroll
            dataLength={data.length}
            next={() => setSize(size + 1)}
            hasMore={!isReachingEnd}
            loader={<div className="text-center">
                <Placeholder />
            </div>}
            endMessage={null}
            style={{ overflow: 'hidden' }}
            scrollThreshold={0.4}
            className="md:grid xl:grid-cols-4 md:grid-cols-2 gap-5 mt-2 px-4 md:px-0"
        >
            {data?.map((pack, index) => (
                <React.Fragment key={index}>
                    {index === 8 ? <div className='xl:col-span-4 md:col-span-2 mb-20'>
                        <DesktopMagicalPacks />
                    </div> : null}
                    {index === 24 ? <div className='xl:col-span-4 md:col-span-2 mb-16'>
                        <DesktopSpecial />
                    </div> : null}
                    <SinglePack1 key={pack.uname} pack={pack} apiType={apiType} />
                </React.Fragment>)
            )}
        </InfiniteScroll >
    </div>
}

const HomePacks = ({ api, query, params, limit = 10, apiType }) => {

    const swr = useSWRInfinite((index, prev) => `${api}?${query}&params=${params ? JSON.stringify(params) : ''}&page=${index + 1}&limit=${limit}`, fetcher, { refreshInterval: 0, revalidateFirstPage: false, initialSize: 1 })

    return <div className="flex overflow-x-scroll px-4 hide-scroll-bar">
        <div className="flex flex-nowrap space-x-4">
            <LazyLoad unmountIfInvisible placeholder={<div className="flex flex-nowrap space-x-4"><Placeholder /></div>}>
                <InfiniteScrollHorizontal
                    swr={swr}
                    loadingIndicator={<div className="flex flex-nowrap space-x-4"><Placeholder /></div>}
                    endingIndicator=""
                    isReachingEnd={(swr) =>
                        swr.error || swr.data?.[0]?.length === 0 || swr.data?.[swr.data?.length - 1]?.length < limit
                    }
                >
                    {(response) => <>
                        {response?.map((pack) => <SinglePack key={pack.uname} pack={pack} apiType={apiType} />)}
                    </>
                    }
                </InfiniteScrollHorizontal >
            </LazyLoad>
        </div>
    </div>
}

export default HomePacks